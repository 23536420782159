
export default {
  name: 'TextblockBlok',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
}
